// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-jsx": () => import("C:\\dev\\lsmagic-portfolio\\src\\templates\\project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-pages-404-jsx": () => import("C:\\dev\\lsmagic-portfolio\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("C:\\dev\\lsmagic-portfolio\\src\\pages\\about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("C:\\dev\\lsmagic-portfolio\\src\\pages\\contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-success-jsx": () => import("C:\\dev\\lsmagic-portfolio\\src\\pages\\contactSuccess.jsx" /* webpackChunkName: "component---src-pages-contact-success-jsx" */),
  "component---src-pages-index-jsx": () => import("C:\\dev\\lsmagic-portfolio\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

exports.data = () => import("C:\\dev\\lsmagic-portfolio\\.cache\\data.json")

