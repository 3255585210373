module.exports = [{
      plugin: require('C:/dev/lsmagic-portfolio/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[],"autoLabel":false,"labelFormat":"[filename]--[local]"},
    },{
      plugin: require('C:/dev/lsmagic-portfolio/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-132416990-1"},
    },{
      plugin: require('C:/dev/lsmagic-portfolio/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#42D3B6"},
    },{
      plugin: require('C:/dev/lsmagic-portfolio/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    }]
